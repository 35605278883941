@import './shared';

.nav-tabs {
  border-bottom: none;

  .nav-item {

    &:first-child {
      .nav-link{
        border-top-left-radius: 0.25rem;
      }
    }

    &:last-child {
      .nav-link {
         border-top-right-radius: 0.25rem;
      }
    }

    .nav-link {
      transition: .25s ease-in-out;
      border-radius: 0;

      &.active,
      &:hover {
        color: $white;
        background-color: $dark;
        border-color: transparent;
      }
    }
  }

}
