@font-face {
  font-family: 'Italian Plate No1';
  src: url(~@nordinvestments/nord-storybook/dist/assets/fonts/ItalianPlateNo1-Regular.eot) format('eot'),
    url(~@nordinvestments/nord-storybook/dist/assets/fonts/ItalianPlateNo1-Regular.ttf) format('truetype'),
    url(~@nordinvestments/nord-storybook/dist/assets/fonts/ItalianPlateNo1-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Italian Plate No1 Mono';
  src: url(~@nordinvestments/nord-storybook/dist/assets/fonts/ItalianPlateNo1Mono-Regular.eot) format('eot'),
    url(~@nordinvestments/nord-storybook/dist/assets/fonts/ItalianPlateNo1Mono-Regular.ttf) format('truetype'),
    url(~@nordinvestments/nord-storybook/dist/assets/fonts/ItalianPlateNo1Mono-Regular.woff) format('woff');
}
