@import '../../styles/shared';

.backdropBackground {
  background: rgba(0, 0, 0, 0.5);

  &.warningModal {
    z-index: $zindex-modal;
  }
}

.customModal {
  @include media-breakpoint-down(lg) {
   max-width: 100%;
   margin: 0;
   padding: 0;
  }
}

.modalContentBorder {
  border: 0.5px solid white;

  @include media-breakpoint-down(lg) {
    min-height: 100vh;
    border-radius: 0;
  }
}

.modalHeight {
  height: 100%;
  overflow: auto !important;

  @include media-breakpoint-up(xl) {
    max-height: 60vh;
  }
}
